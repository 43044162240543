.container {
  :global {
    .footer {
      box-shadow: 0 0 30px rgba(0, 0, 0, .3);
      border-radius: 3px;
    }

    .footer-input {
      color: #363636;
      padding: 20px 30px;
      margin-bottom: 0;
      border: none !important;
      outline: none !important;
      height: 40px;
      font-size: 20px;
      background: #fff;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 64px;
      width: 70%;
    }

    .footer_title .footer-submit {
      padding: 20px 40px;
      background: #FF5421;
      height: 64px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }

    .footer_img {
      width: 50px;
      height: 50px;
    }


  }
}
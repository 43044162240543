@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'simplebar-react/dist/simplebar.min.css';


* {
    font-size: 16px;
}
ul,ol{
    padding-left:20px;
}
.container {
    margin: 0 auto;
}

@media (min-width: 1200px) {
    .container {
        margin: 0 auto;
        max-width: 1200px;
    }
}

@media (max-width: 1024px) {
    .container {
        margin: 0 auto;
        padding: 1rem;
    }
}

.App {
    overflow: hidden;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #fff;
}

.ant-popover .ant-popover-inner {
    padding: 0;
}

.css-djqxq7-MuiPaper-root-MuiAppBar-root {
    z-index: 10 !important;
}

.slick-next {
    right: 20px;
}

.slick-prev {
    left: 20px;
    z-index: 1;
}

/*picture {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin: 7vh;*/
/*}*/



.slick-prev,
.slick-next {
    width: 30px;
    height: 30px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px !important;
}

.ant-image {
    width: 100% !important;
}

.video_trailer iframe {
    width: 100%;
    height: 300px;
}

.teaching_video {
    overflow: hidden;
}

/*.teaching_video iframe {*/
/*    width: 101%;*/
/*}*/

.container .mx-auto ul {
    list-style: none;
    display: flex;
    flex-flow: column wrap;
    /*min-height: 22em;*/
    flex-wrap: wrap;
}

/*.container .mx-auto ul li {*/
/*    !*width:50%;*!*/
/*    padding: 1em;*/
/*    margin: 2px;*/
/*    background-color: rgba(204, 204, 204, 0.91);*/
/*}*/
.container .mx-auto ul {
    color: #000;
    --li-spacing: 1rem;
    margin-left:25px;
    display: grid;
    row-gap: var(--li-spacing);
    list-style: circle;
}


.container .mx-auto br {
    display: none;
}

.list-disc ul {
    list-style-type: circle;
}

.w-full .admin iframe {
    width: 100%;
    height: 100%;
}
.contact-card span {
    display: block;
    color: #101010;
    font-size: 27px;
    font-weight: 600;
    padding: 10px;
}

.client iframe {
    width: 100%;
    height: 440px;
}

.icon img {
    width: 18.56%;
}

.text-blog img{
    background: red;
    display: flex;
    width: 60vw;
    height: 30vw;
    justify-content: center;
    margin:5vh auto 5vh auto;
    object-fit: cover;
}
.title-contact {
    font-size: 36px;
    color: #101010;
    line-height: 46px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 14px;
}
.description-contact{
    font-size: 19px;
    color: #363636;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 24px;
}
